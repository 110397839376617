import React from "react";
import { Element } from "react-scroll";
import { Color } from "../../resources/colours";

//icons import
import {
  FaHtml5,
  FaCss3,
  FaReact,
  FaNode,
  FaPython,
  FaJava,
  FaAndroid,
  FaBootstrap,
  FaGit,
  FaLinux,
} from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { TbBrandReactNative } from "react-icons/tb";
import {
  SiApachecordova,
  SiMicrosoftazure,
  SiCsharp,
  SiFirebase,
  SiMongodb,
  SiMysql,
  SiPostman,
  SiSelenium,
} from "react-icons/si";

import "./Skills.css";

const iconColor = Color.mainColor;

const skills = [
  { name: "HTML5", icon: <FaHtml5 color={iconColor} /> },
  { name: "CSS3", icon: <FaCss3 color={iconColor} /> },
  { name: "JavaScript", icon: <IoLogoJavascript color={iconColor} /> },
  { name: "React", icon: <FaReact color={iconColor} /> },
  { name: "Node.js", icon: <FaNode color={iconColor} /> },
  { name: "Python", icon: <FaPython color={iconColor} /> },
  { name: "Java", icon: <FaJava color={iconColor} /> },
  { name: "Android", icon: <FaAndroid color={iconColor} /> },
  { name: "Apache Cordova", icon: <SiApachecordova color={iconColor} /> },
  { name: "Azure", icon: <SiMicrosoftazure color={iconColor} /> },
  { name: "Bootstrap", icon: <FaBootstrap color={iconColor} /> },
  { name: "C#", icon: <SiCsharp color={iconColor} /> },
  { name: "Firebase", icon: <SiFirebase color={iconColor} /> },
  { name: "Git", icon: <FaGit color={iconColor} /> },
  { name: "Linux", icon: <FaLinux color={iconColor} /> },
  { name: "Mongodb", icon: <SiMongodb color={iconColor} /> },
  { name: "Mysql", icon: <SiMysql color={iconColor} /> },
  { name: "Postman", icon: <SiPostman color={iconColor} /> },
  { name: "React Native", icon: <TbBrandReactNative color={iconColor} /> },
  { name: "Selenium", icon: <SiSelenium color={iconColor} /> },
];

const Skills = () => {
  return (
    <Element name="skill" className="skills-page">
      <div className="skills-content">
        <h2 className="skills-title">My Skills</h2>
        <div className="skills-container">
          {skills.map((skill, index) => (
            <div key={index} className="skill">
              <span className="emoji">{skill.icon}</span>
              <span className="label">{skill.name}</span>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
};

export default Skills;
