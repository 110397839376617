import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import { Carousel } from "react-responsive-carousel";
import { storage } from "../../firebaseConfig";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Projects.css";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const folderRefs = [
          {
            folder: "Links",
            description:
              "Links, developed with React Native, Firebase, and JavaScript, enhances the golfing experience on iOS and Android. It offers encrypted chat, golfer discovery, friend addition, Google Gemini club suggestions, swing analysis, profile customization, score tracking, weather updates, past round posting, a social feed, notifications, and nearby golf course information via Google Places. This feature-rich app connects golfers, provides personalized insights, and tracks progress, all while ensuring a secure and user-friendly experience.",
            repo: "",
          },
          {
            folder: "Checkers",
            description:
              "This checkers game, developed using C# and Windows Forms, offers a straightforward and enjoyable way to play the classic game with friends. The interface is clean and intuitive, designed to facilitate easy navigation and a pleasant user experience.",
            repo: "https://github.com/KkrolkK/SimpleCheckersGame",
          },
          {
            folder: "PlantVsZombies",
            description:
              "A Monogame C# recreation of the beloved Plants vs. Zombies. Strategically plant your defenses to fend off quirky zombies with unique abilities in this strategy-packed adventure.",
            repo: "https://github.com/KkrolkK/Simple-Plants-Vs-Zombies-Monogame",
          },
        ];

        const allProjects = await Promise.all(
          folderRefs.map(async ({ folder, description, repo }) => {
            const folderRef = ref(storage, folder);
            const { items } = await listAll(folderRef);

            const imageUrls = await Promise.all(
              items.map(async (item) => {
                const url = await getDownloadURL(item);
                return { url, name: item.name };
              })
            );

            return { folder, description, repo, images: imageUrls };
          })
        );

        setProjects(allProjects);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  return (
    <Element name="projects" className="projects-section">
      <div className="projects-content">
        <h1 className="projTitle">Projects</h1>
        <div className="projects-grid">
          {projects.map((project, index) => (
            <div className="project-card" key={index}>
              <h3 className="projectName">{project.folder}</h3>
              <Carousel showThumbs={false}>
                {project.images.map((image, idx) => (
                  <img key={idx} src={image.url} alt={image.name} />
                ))}
              </Carousel>
              <p className="project-description">{project.description}</p>
              {project.repo ? (
                <a
                  className="button"
                  href={project.repo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub Repository
                </a>
              ) : (
                <p className="soon">Coming soon...</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
};

export default Projects;
