import React from "react";
import { Element } from "react-scroll";
import Photo from "../../resources/picture.png";

import "./Home.css";

const Home = () => {
  return (
    <Element name="home" className="home-section">
      <div className="about-me-container">
        <div className="about-me-content">
          <div className="about-me-image">
            <img src={Photo} alt="About Me" />
          </div>
          <div className="about-me-text">
            <h2>Caroline Konikiewez Amorim</h2>
            <p>
              <br />
              Hey! I'm Caroline, a dedicated software developer with a passion
              for problem solving and new challenges.
              <br />
              <br />
              I finished high school in Brazil in 2017 and began studying
              mechatronics engineering at UFRGS in January 2018. However, due to
              the COVID-19 pandemic, I reconsidered my career path and decided
              to pursue my dream of studying programming in Canada.
              <br />
              <br />
              In Canada, I studied computer programming at Conestoga College,
              where I graduated with high distinction. I also worked on an
              award-winning capstone project as the lead developer,
              collaborating with classmates and a client.
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Home;
