import "./App.css";

//screens
import Home from "./screens/Home/Home";
import Projects from "./screens/Projects/Projects";
import Contact from "./screens/Contact/Contact";
import Skills from "./screens/Skills/Skills";

// components
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

const link = document.createElement("link");
link.href =
  "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);

function App() {
  return (
    <div>
      <NavBar />
      <Home />
      <Projects />
      <Skills />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
