import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          &copy; {new Date().getFullYear()} Caroline Konikiewez Amorim. All
          rights reserved.
        </p>
        <div className="footer-links">
          <a
            href="https://github.com/KkrolkK"
            target="_blank"
            rel="noopener noreferrer"
          >
            GitHub
          </a>
          <a
            href="https://www.linkedin.com/in/caroline-amorim-47698a252/"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a href="mailto:carolkonikiewez@gmail.com">Email</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
