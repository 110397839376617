import { Link } from "react-scroll";
import React, { useState } from "react";

import { AiFillInstagram } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillHome } from "react-icons/ai";
import { AiFillProduct } from "react-icons/ai";
import { AiFillProject } from "react-icons/ai";
import { AiFillMail } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineMenu } from "react-icons/ai";

import { Color } from "../../resources/colours.js";
import Photo from "../../resources/emoji.png";

import "./NavBar.css";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="headerDiv">
      <div className="social-links">
        <a
          href="https://www.instagram.com/kcarol_amorim/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillInstagram size="30" color={Color.lightAccent} />
        </a>
        <a
          href="https://github.com/KkrolkK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillGithub size="30" color={Color.lightAccent} />
        </a>
        <a
          href="https://www.linkedin.com/in/caroline-amorim-47698a252/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillLinkedin size="30" color={Color.lightAccent} />
        </a>
      </div>
      <nav>
        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? (
            <AiOutlineClose size="30" color={Color.mainColor} />
          ) : (
            <AiOutlineMenu size="30" color={Color.mainColor} />
          )}
        </div>
        <div className={`nav-links ${isOpen ? "open" : ""}`}>
          <Link className="react-scroll" to="home" smooth={true} duration={500}>
            <AiFillHome size="40" color={Color.mainColor} />
            Home
          </Link>
          <Link
            className="react-scroll"
            to="projects"
            smooth={true}
            duration={500}
          >
            <AiFillProject size="40" color={Color.mainColor} />
            Projects
          </Link>
          <Link
            className="react-scroll"
            to="skill"
            smooth={true}
            duration={500}
          >
            <AiFillProduct size="40" color={Color.mainColor} />
            Skills
          </Link>
          <Link
            className="react-scroll"
            to="contact"
            smooth={true}
            duration={500}
          >
            <AiFillMail size="40" color={Color.mainColor} />
            Contact
          </Link>
        </div>
      </nav>
      <img src={Photo} alt="emoji" />
    </div>
  );
}

export default NavBar;
