export const Color = {
  lightShades: "#F2F5F7",

  lightAccent: "#E18279",

  mainColor: "#79CF9E",

  darkAccent: "#B19F9E",

  darkShades: "#373554",
};
